const { Component } = React
const { observer } = mobxReact
const { observable } = mobx

import FileUploader from '../MycFileUploader/FileUploader'
import TableImage from '../../components/table-image'

let Editor = () => <div></div>
let draftJs
let draftjsToHtml
let htmlToDraftjs
let EditorState
let ContentState
let convertToRaw
let toHTML

if (typeof window !== 'undefined') {
	Editor = require('react-draft-wysiwyg').Editor

	draftJs = require('draft-js')
	draftjsToHtml = require('draftjs-to-html')
	htmlToDraftjs = require('html-to-draftjs').default

	EditorState = draftJs.EditorState
	ContentState = draftJs.ContentState
	convertToRaw = draftJs.convertToRaw

	toHTML = (state) => {
		return draftjsToHtml(convertToRaw(state.getCurrentContent()))
	}
}

@observer
class List extends Component {
	@observable list_state = [
		'',
		''
	]

	constructor (props) {
		super(props)

		this.list_state = [
			...store.root.forms[props.form].values[props.name].value,
			''
		]
	}

	render () {
		const {
			name,
			uiControllerSettings = {},
			onChange
		} = this.props

		const {
			limit = -1,
			placeholder = ''
		} = uiControllerSettings

		// const form = store.root.forms[this.props.form]
		// const value = form.values[name] && form.values[name].value

		return (
			<div className="list ltr ta_l">
				{this.list_state
				.filter((value, i) => {
					if (limit > -1)
						return i < limit
					else return true
				})
				.map((value, i) => {
					return (
						<div
							key={i}
							className="list-item p_r c_p"
						>
							{(limit !== 1 && value !== '') && (
								<i
									className={`icon-close icons`}
									onClick={() => {
										this.list_state.splice(i, 1)

										onChange({
											target: {
												value: this.list_state.filter(v => v != '')
											}
										})
									}}
								/>
							)}
							<div className="list-input d_f f_ai_c">
								{(name === 'images' || name === 'image') && (
									<TableImage
										url={value}
									/>
								)}
								<input
									placeholder={placeholder}
									className="f_g"
									type="text"
									value={value}
									onChange={e => {
										this.list_state[i] = e.target.value

										if (i === this.list_state.length - 1) {
											this.list_state[this.list_state.length] = ''
										}

										onChange({
											target: {
												value: this.list_state.filter(v => v != '')
											}
										})
									}}
								/>
							</div>
						</div>
					)
				})}
			</div>
		)
	}
}

@observer
class WYSIWYG extends Component {
	@observable editor_state

	constructor (props) {
		super(props)

		const form = store.root.forms[props.form]
		const value = form.values[props.name] && form.values[props.name].value

		if (!value || value === '')
			this.editor_state = EditorState.createEmpty()
		else {
			const contentBlock = htmlToDraftjs(value)

			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
				const editor_state = EditorState.createWithContent(contentState)

				this.editor_state = editor_state
			}
		}
	}

	render () {
		const {
			name,
			onChange,
			onFocus,
			onBlur
		} = this.props

		const form = store.root.forms[this.props.form]

		try {
			if (typeof window !== 'undefined') {
				return form.values[name] && (
					<div>
						<Editor
							editorState={this.editor_state}
							toolbarClassName="toolbarClassName"
							wrapperClassName="wrapperClassName"
							editorClassName="editorClassName"
							onEditorStateChange={state => {
								this.editor_state = state
								onChange({
									target: {
										value: toHTML(state)
									}
								})
							}}
							onFocus={onFocus}
							onBlur={onBlur}
							textAlignment="right"
						/>
					</div>
				)
			} else {
				return <div></div>
			}
		} catch (e) {
			return <div>{e.message}</div>
		}
	}
}

@observer
class FormFileUploader extends Component {
	render () {
		const {
			name,
			editMode
		} = this.props

		const form = store.root.forms[this.props.form]
		const value = form.values[name] && form.values[name].value

		if (editMode) {
			return (
				<div className="img x2"
				     style={{
					     backgroundImage: `url(${store.api}/uploads/${value}_1.jpg)`
				     }}
				/>
			)
		} else {
			return (
				<FileUploader
					name={name}
					text="בחר קובץ"
					onChange={res => {
						store.set(`forms.${this.props.form}.values.file`, {
							value: res.blobs
						})

					}}
				/>
			)
		}
	}
}

@observer
export default class FormField extends Component {

	constructor (props) {
		super(props)

		const { form, name } = props
		const field = store.root.forms[form].values[name] || {}
		const { value } = field

		store.set(`forms.${form}.values.${name}.value`, value || '')
	}

	onChange = e => {
		const { name } = this.props
		const form = store.root.forms[this.props.form]

		store.set(`forms.${this.props.form}.values.${name}.value`, e.target.value)

		if (!form.values[name].dirty)
			store.set(`forms.${form}.values.${name}.dirty`, e.target.value !== '')
	}

	onFocus = e => {
		const { form, name } = this.props
		store.set(`forms.${form}.values.${name}.focused`, true)
	}

	onBlur = e => {
		const { form, name } = this.props
		store.set(`forms.${form}.values.${name}.focused`, false)
	}

	controllers = () => {
		const {
			name,
			form,
			uiController = 'text',
		} = this.props

		const type = this.props.type || uiController
		const _form = store.root.forms[form]
		const value = _form.values[name] && _form.values[name].value

		const events = {
			onChange: this.onChange,
			onFocus: this.onFocus,
			onBlur: this.onBlur,
		}

		switch (type) {
			case 'list':
				return <List
					{...this.props}
					{...events}
				/>

			case 'wysiwyg':
				return <WYSIWYG
					{...this.props}
					{...events}
				/>

			case 'file-uploader':
				return (
					<FormFileUploader
						{...this.props}
						{...events}
					/>
				)

			default:
				return _form.values[name] && (
					<input
						name={name}
						type={type}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						onChange={this.onChange}
						value={value}
					/>
				)
		}
	}

	render () {
		const {
			form,
			name,
			label,
			uiController = 'text',
			required
		} = this.props

		const type = this.props.type || uiController
		const _form = store.root.forms[form]
		const focused = _form.values[name] && _form.values[name].focused
		const dirty = _form.values[name] && _form.values[name].dirty
		const hideLabel = type === 'wysiwyg' || (name === 'images' || name === 'image')

		return (
			<div className={classNames('form-field', {
				input: type === 'text' || type === 'password',
				focused,
				dirty
			})}>

				{!hideLabel && (
					<label className={classNames({
						float: type === 'text' || type === 'password'
					})} htmlFor={`${form}_${name}`}>{label} {required &&
					<strong style={{ color: 'red' }}>*</strong>}</label>
				)}

				{this.controllers()}
			</div>
		)
	}
}