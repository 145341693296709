const { Component, Fragment } = React
const { observer } = mobxReact
const { Route, Switch, withRouter, Redirect } = ReactRouterDOM

import Login from './views/Login'
import Dashboard from './views/Dashboard'

@observer
@withRouter
export default class Routes extends Component {
	render () {
		return (
			<Fragment>
				{!store.isLoggedIn()
					? <Redirect to="/login"/>
					: (
						<Switch>
							<Route
								path="/dashboard/:schema"
								component={Dashboard}
							/>

							<Route
								path="*"
								render={() => <Redirect to="/dashboard/Project"/>}
								exact
							/>

						</Switch>
					)
				}

				<Route
					path="/login"
					component={Login}
					exact
				/>

			</Fragment>
		)
	}
}