const { Component } = React
const { observer } = mobxReact
const { observable } = mobx

@observer
export default class Form extends Component {
	@observable sending = false
	@observable error = null

	constructor (props) {
		super(props)

		const { id } = props

		if (!id) throw new Error('missing form id')

		if (!store.root.forms[id])
			store.set(`forms.${id}.values`, {})
	}

	componentWillUnmount () {
		const { id } = this.props

		delete store.root.forms[id]
	}

	onSubmit = async e => {
		e.preventDefault()

		const { id, custom } = this.props

		this.sending = true

		let fields = {}

		function getFormFields () {
			const elements = document.getElementById(e.target.id).elements
			const obj = {}
			for (let i = 0; i < elements.length; i++) {
				const item = elements.item(i)
				obj[item.name] = item.value
			}

			return obj
		}

		if (custom) {
			fields = (() => {
				const form = store.root.forms[id]
				let result = {}

				Object.keys(form.values).forEach(item => {
					result[item] = form.values[item].value
				})

				return result
			})()
		} else {
			fields = getFormFields()
		}

		try {
			this.props.onSubmit && await this.props.onSubmit(fields)
			this.error = null
		} catch (e) {
			console.error(e)
			console.error(e.message)
			this.error = e.message
		} finally {
			this.sending = false
		}
	}

	render () {
		const { children, id } = this.props

		return (
			<form id={id} onSubmit={this.onSubmit}>

				{this.sending && (
					<div className="p_a stretch mask fc">
						<div className="loader"/>
					</div>
				)}

				{children}

				<div>{this.error}</div>
			</form>
		)
	}
}