import Form from '../templates/Form/Form'

const { Component } = React
const { observer } = mobxReact

@observer
export default class RemoveDialog extends Component {
	onClose = () => {
		delete store.root.flags['removeDialog']
	}

	render () {
		const { schema } = this.props
		const itemId = store.root.flags['removeDialog']
		const item = store.root.database[schema][itemId] || {}

		return (
			<div className="modal-c">
				<div className="mask mask_d p_a stretch"
				     onClick={this.onClose}/>
				<div className="p_a box modal-box ta_c">
					<div className="modal-header pad">
						<h3>{locale[`remove_warning_title`]}</h3>
					</div>

					<section>
						<p className="pad">{locale[`remove_warning_desc`]} {item.title || item._id}?</p>
					</section>

					<footer className="stretch_w d_f">
						<button className="f_1"
						        onClick={this.onClose}>ביטול
						</button>

						<div className="f_1">
							<Form id={`remove_${schema}`}
							      onSubmit={async (e) => {
								      const _id = store.root.flags['removeDialog']
								      store.remove({ schema, _id })

								      this.onClose()
							      }}>
								<button type="submit" className="f_1 main d_b stretch_w">מחיקה</button>
							</Form>
						</div>

					</footer>
				</div>
			</div>
		)
	}
}