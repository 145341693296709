const { Component } = React
const { withRouter } = ReactRouterDOM
const { observer } = mobxReact

import ManagerTable from './manager-table'
import CreateDialog from './create-dialog'

@withRouter
@observer
export default class Manager extends Component {
	render () {
		let { createDialog } = store.root.flags
		const { match: { params } } = this.props
		const { schema } = params || {}
		const model = store.root.schemas[schema] || {}
		const columns = Object.keys(model).sort((a,b) => {
			// if (a.indexOf('image') === 0) return -1
			return 1
		})
		const items = Object.keys(store.root.database[schema] || {})

		let icon = (() => {
			switch (schema) {
				case 'Project':
					return 'folder-alt'
				case 'BlogPost':
					return 'docs'
				case 'Asset':
					return 'picture'
			}
		})()

		return (
			<div className="manager-container">
				<div className="d_f page-header">
					<div className="f_g d_f f_ai_c">
						<h2>
							<i className={`icon-${icon} icons`}/>&nbsp;&nbsp;
							{locale[`admin_${schema}`]}
						</h2>
					</div>

					<button
						className="main sml"
						onClick={() => store.set('flags.createDialog', schema)}
					>
						{locale.new} {locale[`${schema}`]}
					</button>
				</div>

				<div className="manager-table-container">
					<ManagerTable
						schema={schema}
						columns={columns}
						items={items.map(id => store.root.database[schema][id])}
					/>
				</div>

				{schema === createDialog && (
					<CreateDialog
						schema={schema}
					/>
				)}
			</div>
		)
	}
}