// import uuidv4 from 'uuid/v4'
const { Component } = React
const { observer } = mobxReact
// import Button from '../MycUI/Button/Button'
import './FileUploader.scss'

let findDOMNode

if (typeof ReactDOM !== 'undefined') {
	findDOMNode = ReactDOM.findDOMNode
}

const blobToFile = (theBlob, fileName) => {
	theBlob.lastModifiedDate = new Date()
	theBlob.name = fileName
	return theBlob
}

@observer
export default class FileUploader extends Component {
	constructor (props) {
		super(props)
		// this.myRef = React.createRef()
	}

	state = {
		file: null,
		size: null,
		type: null,
		videoSrc: null,
		imageSrc: null,
		blobs: []
	}

	onChange = (e) => {
		const file = e.target.files[0]
		const { size, type } = file

		if (type.indexOf('video') > -1) {
			const videoSrc = window.URL.createObjectURL(file)

			this.setState({
				file,
				size,
				type,
				videoSrc,
				imageSrc: null
			}, () => {
				this.props.onChange && this.props.onChange(this.state)
			})
		}

		if (type.indexOf('image') > -1) {

			let FR = new FileReader()

			FR.addEventListener('load', async (e) => {
				const { image } = this.refs
				const imageSrc = e.target['result']
				image['src'] = imageSrc

				image['onload'] = async () => {
					console.log('image loaded')
					const blobs = await this.assetResizer(image, [150, 1200], type)

					this.setState({
						file,
						size,
						type,
						imageSrc,
						blobs
					}, () => {
						this.props.onChange && this.props.onChange(this.state)
					})
				}
			})

			FR.readAsDataURL(file)
		}
	}

	applyCanvas = (image, _size = 500, _type) => {
		return new Promise(resolve => {
			const { width, height } = image
			const aspect = width / height
			const ori = width >= height ? 'landscape' : 'portrait'
			const canvas = document.createElement('canvas')
			const ctx = canvas.getContext('2d')
			const size = Math.min(_size, width)

			findDOMNode(this.refs.myRef).appendChild(canvas)

			canvas.width = size
			canvas.height = size / aspect

			ctx.drawImage(image, 0, 0, canvas.width, canvas.height)

			resolve({
				width: canvas.width,
				height: canvas.height,
				ori,
				size: _size,
				type: _type,
				imageSrc: canvas.toDataURL('image/jpeg', .77),
			})

			findDOMNode(this.refs.myRef).removeChild(canvas)
		})
	}

	componentWillUnmount () {
		findDOMNode(this.refs.myRef).innerHTML = ''
	}

	assetResizer = async (image, sizes, type) => {
		let results = []

		for (let size of sizes) {
			results.push(await this.applyCanvas(image, size, type))
		}

		return results
	}

	render () {
		const { name, text } = this.props
		const { type, size, videoSrc, imageSrc } = this.state

		return (
			<div className="file-uploader" ref="uploader">
				<div ref="myRef" className="image-loader"/>

				<div className="preview" ref="preview">
					<video src={videoSrc} controls
					       style={{ display: videoSrc ? 'block' : 'none' }}/>
					<img ref="image" alt=""
					     style={{ display: imageSrc ? 'block' : 'none' }}/>
					{imageSrc && (
						<div className="file-details">
							<p>Type: {type}</p>
							<p>Size: {size / 1000}KB</p>
						</div>
					)}
				</div>
				<div className="file-uploader-footer">
					<button className="main choose inline">
						{text || 'Choose file'}
						<input
							name={name}
							type="file"
							ref="uploadInput"
							onChange={this.onChange}
						/>
					</button>
				</div>
			</div>
		)
	}
}
