import { hot } from 'react-hot-loader/root'
import Form from '../Form/Form'
import FormField from '../Form/FormField'

const { observer } = mobxReact
const { withRouter } = ReactRouterDOM

@hot
@observer
@withRouter
export default class Login extends React.Component {
	render () {
		const { logo, callback, history } = this.props

		return (
			<div className="p_f fc stretch">
				<Form id="login" onSubmit={async (fields) => {
					const res = await store.login(fields)

					if (res)
						history.push('/dashboard/Project')
				}}>
					<div className="box shadow">
						<div className="pad">
							<div className="cc">
								{logo}
							</div>

							<hr/>

							<FormField
								form="login"
								name="email"
								label="אימייל"
							/>

							<FormField
								form="login"
								name="password"
								type="password"
								label="סיסמא"
							/>
						</div>

						<button className="c_p d_b stretch_w main ta_c">
							כניסה
						</button>
					</div>
				</Form>
			</div>
		)
	}
}
