import Manager from '../components/manager'

const { Component, Fragment } = React
const { observer } = mobxReact

import { Helmet } from 'react-helmet'
import Logo from 'core/react/components/Logo/Logo'
import Spinner from 'core/react/components/Spinner/Spinner'
import Dropdown from 'core/react/components/Dropdown/Dropdown'

const { NavLink, withRouter } = ReactRouterDOM

@withRouter
@observer
export default class Dashboard extends Component {
	state = {
		loading: true,
	}

	componentDidMount = async () => {
		await store.login()
		await store.dashboard()

		this.setState({
			loading: false
		})
	}

	render () {
		const { history } = this.props
		const { loading, menuOpen } = this.state
		const navItems = Object.keys(store.root.schemas).reverse()

		return (
			<div id="dashboard">
				<Helmet>
					<title>נווה בניין | פאנל ניהול</title>
				</Helmet>

				{loading ? (
					<Spinner/>
				) : (
					<Fragment>
						<header>
							<Dropdown
								className="menu-container"
								trigger={(
									<div className="menu-btn">
										<i className="icon-options-vertical icons"/>
									</div>
								)}
							>
								<ul>
									<li>
										<h3>ברוך הבא,</h3>
										{store.user.email}
									</li>
									<li className="vis_s">
										<hr/>
									</li>
									{navItems.map(schema => {
										return (
											<li className="vis_s" key={schema}>
												<NavLink
												         to={`/dashboard/${schema}`}
												>
													<button className="sml">
														{locale[`admin_${schema}`]}
													</button>
												</NavLink>
											</li>
										)
									})}
									<li>
										<hr/>
									</li>
									<li>
										<button className="sml stretch_w" onClick={async () => {
											await store.logout()

											history.push('/login')
										}}>התנתק
										</button>
									</li>
								</ul>

							</Dropdown>

							<div className="f_g hid_s">
								<nav className="stretch_h f_h d_f f_jc_c">
									{navItems.map(schema => {
										return (
											<NavLink
												key={schema}
											         to={`/dashboard/${schema}`}
											>{locale[`admin_${schema}`]}
											</NavLink>
										)
									})}
								</nav>
							</div>

							<Logo/>

						</header>

						<Manager/>
					</Fragment>
				)}

			</div>
		)
	}
}