const { Component, createRef } = React

import './Dropdown.scss'

export default class Dropdown extends Component {
	constructor (props) {
		super(props)

		this.trigger = createRef()
	}

	state = {
		open: false
	}

	toggleOpen = (e) => {
		if (this.state.open) {
			this.close()
		} else {
			this.open()
		}
	}

	open = () => {
		document.body.addEventListener('click', this.bodyBind)

		this.setState({
			open: true
		})
	}

	close = () => {
		document.body.removeEventListener('click', this.bodyBind)

		this.setState({
			open: false
		})
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.bodyBind)
	}

	bodyBind = e => {
		const inside = e.target.closest('.dd-container')

		if (!inside) {
			e.stopPropagation()

			this.close()

		}

	}

	render () {
		const {
			className,
			trigger,
			children
		} = this.props

		const {
			open
		} = this.state

		return (
			<div className={classNames('dd-container', className)}>
				<div
					className="dd-trigger"
					onClick={this.toggleOpen}
					ref={this.trigger}
				>
					{trigger}
				</div>
				{open && <div className="dd">
					{children}
				</div>}
			</div>
		)
	}
}