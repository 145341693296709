const { render } = ReactDOM
const { BrowserRouter } = ReactRouterDOM

const Store = require('./store').default

global.store = new Store({
	schemas: {},
	flags: {
		selectedTab: 'Project'
	}
})

global.locale = require('./locale').he

const App = require('./app').default

render((
	<BrowserRouter
		className="router"
	>
		<App />
	</BrowserRouter>
), document.getElementById('root'))