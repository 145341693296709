const { Component } = React

class ImageModal extends Component {
	render () {
		const { url, onClose } = this.props

		const sliced = url.slice(-6)
		const customImage = sliced === '_0.jpg' || sliced === '_1.jpg'
		const bgImageUrl = customImage? url.replace('_0.jpg', '_1.jpg') : url

		return (
			<div className="modal-c image-modal">
				<div className="mask mask_d p_a stretch"
				     onClick={onClose}/>
				<div className="p_a box modal-box ta_c transparent">
					<section>
						<div className="f_g ta_c">
							<img src={bgImageUrl} className="d_ib"/>
						</div>
						{/*<div className="img lg"
						     style={{
							     backgroundImage: `url(${bgImageUrl})`
						     }}
						/>*/}
						{/*<p className="pad">{locale[`remove_warning_desc`]} {item.title || item._id}?</p>*/}
					</section>

					<footer className="stretch_w ">
						<button className=""
						        onClick={onClose}>סגור
						</button>
					</footer>
				</div>
			</div>
		)
	}
}

export default class TableImage extends Component {
	state = {
		open: false
	}

	toggleOpen = () => {
		this.setState({
			open: !this.state.open
		})
	}

	render () {
		const { url } = this.props
		const { open } = this.state

		return (
			<div className="table-img">
				<div className="img"
				     style={{
					     backgroundImage: `url(${url})`
				     }}
				     onClick={this.toggleOpen}
				/>

				{open && (
					<ImageModal
						url={url}
						onClose={this.toggleOpen}
					/>
				)}
			</div>
		)
	}
}