module.exports = async function (url, options = {}) {
	try {
		const res = await fetch(url, {
			method: options.body ? 'POST' : 'GET',
			headers: {
				...options.body ? {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				} : {},
				...options.headers
			},
			...options.body ? {
				body: JSON.stringify(options.body)
			} : {}
		})
		return await res.json()
	} catch (err) {
		throw new Error(err)
	}
}