import EditDialog from './edit-dialog'

const { Component } = React
const { observer } = mobxReact

import RemoveDialog from './remove-dialog'
import TableImage from './table-image'

@observer
export default class ManagerTable extends Component {
	onClose = () => {
		delete store.root.flags['removeDialog']
	}

	render () {
		const { schema, columns = [], items = [] } = this.props

		return (
			<div className="table-c">
				<table>
					<thead className="">
					<tr>
						{columns.map(col => {
							switch (col) {
								case 'content':
									break
								default:
									const text = locale[`${schema}_${col}`]
									return (
										<th key={col}>{text}</th>
									)
							}

						})}
						<th className="last"/>
					</tr>
					</thead>

					<tbody>

					{items.map((item, i) => {
						return (
							<tr key={i}>
								{columns.map((col, j) => {
									switch (col) {
										case 'images':
										case 'image':
											return (
												<td key={j}>
													<div className="d_f table-images">
														{item[col].map((image, i) => {
															return image !== '' && (
																<TableImage
																	key={i}
																	url={image}
																/>
															)
														})}
													</div>
												</td>
											)
										case 'file':
											return (
												<td key={j}>
													<div className="d_f table-images">
														<TableImage
															url={`${store.api}/uploads/${item[col]}_0.jpg`}
														/>
													</div>
												</td>
											)
										case 'content':
											break
										default:
											return (
												<td key={j}>{item[col]}</td>
											)
									}
								})}

								<td>
									<div className="d_f f_jc_fe">
										<button
											onClick={() => {
												const editItem = store.root.database[schema][item._id]
												Object.keys(editItem).forEach(key => {
													store.set(`forms.edit_${schema}.values.${key}.value`, editItem[key])
													store.set(`forms.edit_${schema}.values.${key}.dirty`, true)
												})

												store.set(`flags.editDialog`, item._id)
											}}
											className="main tiny">
											<i className={`icon-pencil icons`}/>
										</button>
										<div>&nbsp;</div>
										<button
											onClick={() => store.set(`flags.removeDialog`, item._id)}
											className="main tiny">
											<i className={`icon-trash icons`}/>
										</button>
									</div>
								</td>
							</tr>
						)
					})}

					</tbody>
				</table>

				{store.root.flags['removeDialog'] && (
					<RemoveDialog
						schema={schema}
					/>
				)}

				{store.root.flags['editDialog'] && (
					<EditDialog
						schema={schema}
					/>
				)}
			</div>
		)
	}
}