const { observable } = mobx
const PROD = process.env.NODE_ENV === 'production'

import fetch from 'core/react/store/fetch'
import set from 'core/react/store/set'
import remove from 'core/react/store/remove'

export default class Store {
	api = PROD
		? 'https://api-v2.nevebinyan.co.il'
		: 'http://localhost:3000'

	ui = observable({
		selectedTab: 'Project',
		selectedModal: null
	})

	user = observable({
		token: localStorage.getItem('token')
	})

	constructor (props) {
		this.root = observable({
			flags: observable(props.flags || {}),
			forms: observable(props.forms || {}),
			schemas: observable(props.schemas || {}),
			database: observable(props.database || {}),
		})
	}

	set = set.bind(this)
	delete = remove.bind(this)
	fetch = fetch.bind(this)

	/*
	* */

	isLoggedIn = () => {
		return this.user.token
	}

	login = async (props = {}) => {
		const { email, password } = props

		const res = await store.fetch(store.api + '/login', {
			body: { email, password, token: this.user.token }
		})

		if (res.status !== 500) {
			this.applyUser(res)

			return res
		}
	}

	logout = async (props = {}) => {
		localStorage.removeItem('token')

		this.user = {}
	}

	applyUser = (user) => {
		localStorage.setItem('token', user.token)
		this.user = user
	}

	dashboard = async () => {
		const res = await store.fetch(store.api + '/dashboard', {
			headers: {
				Authorization: `Bearer ${this.user.token}`
			}
		})

		this.applyDashboard(res)
	}

	applyDashboard = (res) => {
		this.root.schemas = observable(res.schemas || {})
		this.root.database = observable(res.database || {})
	}

	create = async ({ schema, fields }) => {
		let res

		try {
			res = await store.fetch(store.api + '/create', {
				body: { schema, fields },
				headers: {
					Authorization: `Bearer ${this.user.token}`
				}
			})
		} catch (err) {
			console.error(err.message)
		} finally {
			if (res.status !== 500)
				this.applyCreate(schema, res)
		}
	}

	applyCreate = (schema, res) => {
		this.root.database[schema][res._id] = res
	}

	edit = async ({ schema, fields }) => {
		let res

		try {
			res = await store.fetch(store.api + '/edit', {
				body: { schema, fields },
				headers: {
					Authorization: `Bearer ${this.user.token}`
				}
			})
		} catch (err) {
			console.error(err.message)
		} finally {
			if (res.status !== 500)
				this.applyEdit(schema, res)
		}
	}

	applyEdit = (schema, res) => {
		this.root.database[schema][res._id] = res
	}

	remove = async ({ schema, _id }) => {
		let res

		try {
			res = await store.fetch(store.api + '/remove', {
				body: { schema, fields: { _id } },
				headers: {
					Authorization: `Bearer ${this.user.token}`
				}
			})
		} catch (err) {
			console.error(err.message)
		} finally {
			if (res.status !== 500)
				this.applyRemove(schema, _id)
		}
	}

	applyRemove = (schema, _id) => {
		delete this.root.database[schema][_id]
	}
}