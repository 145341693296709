import Login from '../templates/Login/Login'

const { Component } = React

import { Helmet } from 'react-helmet'
import Logo from 'core/react/components/Logo/Logo'

export default class LoginView extends Component {
	render () {
		return (
			<div id="home">
				<Helmet>
					<title>נווה בניין | התחברות</title>
				</Helmet>

				<Login
					logo={<Logo/>}
				/>

			</div>
		)
	}
}