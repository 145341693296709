import './styles/main.scss'

/*
* */

const { Component, Fragment } = React

import { Helmet } from 'react-helmet'
import { hot } from 'react-hot-loader/root'

import Routes from './routes'

@hot
export default class App extends Component {
	render () {
		return (
			<Fragment>
				<Helmet>
					<link rel="icon" type="image/png"
					      href={require('./assets/favicon.ico').default} sizes="16x16"/>
				</Helmet>

				<Routes/>
			</Fragment>

		)
	}
}