import React from 'react'
import './Logo.scss'

const LogoShape = (
	<svg viewBox="0 0 329.49 580.23" id="LogoShape">
		<polygon className="logo_shape_1"
		         points="35.36 580.23 118.46 518.82 253.93 577.07 255.8 541.44 118.03 480.64 37.19 545.41 35.36 580.23"/>
		<polygon className="logo_shape_1"
		         points="13.19 523.05 122.5 441.86 296.02 517.57 297.89 481.95 122.06 403.68 15.02 488.23 13.19 523.05"/>
		<polygon className="logo_shape_2"
		         points="1.89 459.92 126.32 368.95 318.85 453.82 320.27 426.6 125.44 339.18 3.27 433.51 1.89 459.92"/>
		<polygon className="logo_shape_1"
		         points="0 403.01 129.42 309.72 328.29 397.64 329.49 374.63 128.33 284.15 1.16 380.81 0 403.01"/>
		<polygon className="logo_shape_1"
		         points="7.22 345.74 132.43 252.23 323.11 335.5 324.1 316.7 131.12 230.87 8.16 327.75 7.22 345.74"/>
		<polygon className="logo_shape_1"
		         points="22.52 295.04 134.9 205.07 312.96 287.69 313.73 273.09 133.37 187.92 23.25 281.25 22.52 295.04"/>
		<polygon className="logo_shape_1"
		         points="37.8 245.04 137.12 162.83 294.59 235.93 295.36 221.33 135.58 145.67 38.52 231.25 37.8 245.04"/>
		<polygon className="logo_shape_1"
		         points="57.31 194.44 139.16 123.97 267.85 182.9 268.62 168.3 137.62 106.81 58.04 180.66 57.31 194.44"/>
		<polygon className="logo_shape_1"
		         points="72.32 149.45 140.89 90.92 245.01 135.93 245.78 121.33 139.35 73.77 73.05 135.66 72.32 149.45"/>
		<polygon className="logo_shape_1"
		         points="96.01 99.72 142.67 56.92 209.61 87.32 210.38 72.72 140.91 43.97 96.73 85.93 96.01 99.72"/>
		<polygon className="logo_shape_1"
		         points="119.61 51.87 142.76 30.27 182.09 49.21 182.56 40.36 142.16 20.24 120.11 42.29 119.61 51.87"/>
		<polygon className="logo_shape_1"
		         points="143.22 0 125.59 18.06 125.09 27.64 143.62 8.99 170.7 25.23 171.07 18.14 143.22 0"/>
	</svg>
)

const LogoText = (
	<svg viewBox="0 0 495.72 192.28" id="LogoText">
		<g>
			<path className="logo_text_1"
			      d="M774,895.41v8.41h15c22.28,0,26.43-11.39,26.43-31.28V792.72H782.7v8.41h18.13v76.49c0,11.74-2.4,17.78-15.83,17.78H774Zm-29.16,8.41h14.52V792.72H744.8v111.1Zm-40.63,0H718.7V792.72H704.17v111.1Zm-94.69-111.1v8.41h31.67c17.58,0,24.13,6.31,24.13,29.44v73.25h14.52v-71c0-31.36-9.61-40.13-38.33-40.13h-32Zm0.11,111.1h14.52v-68.6H609.6v68.6Zm-54.39-8.41H541.56V792.72H486.62v8.41h40.74v94.27H486v8.41h69.23v-8.41Zm-129.63,0v8.41h15c22.28,0,26.43-11.39,26.43-31.28V792.72H434.31v8.41h18.13v76.49c0,11.74-2.4,17.78-15.83,17.78h-11Zm-27.85-44.16H412V792.72H397.73v58.53Zm-38.23,0h14.31V792.72H359.5v58.53Zm-39.86,79.82h14.85V792.72H319.63V931.07Z"
			      transform="translate(-319.63 -792.72)"/>
			<path className="logo_text_2"
			      d="M773.82,937v3.47h13.07c7.25,0,10,2.6,10,12.14v30.22h6V953.55c0-12.94-4-16.55-15.81-16.55h-13.2Zm0,45.83h6v-28.3h-6v28.3ZM736.16,937v3.47h9.73c9.6,0,10.09,5.85,10.09,12.58v29.79h6.08V957.17c0-11.64,0-20.17-15.5-20.17H736.16Zm-4,42.36h-5.63V937H703.85v3.47h16.8v38.89H703.58v3.47h28.56v-3.47ZM665.42,937v3.47h13.07c7.25,0,10,2.6,10,12.14v30.22h6V953.55c0-12.94-4-16.55-15.81-16.55h-13.2Zm0,45.83h6v-28.3h-6v28.3Zm-46.86,0h16V954.75c0-11.64-3.42-17.75-16.08-17.75H602.17v3.47h7.48L602,982.83h6.35l7.16-42.36h3.11c7.25,0,10,3.65,10,10.77v28.12h-10v3.47ZM586,937v27.4c0,7.88-.67,10.52-8.79,12.79L571.35,937h-5.68l6.13,41.67L560,981.6l1.4,3.4s8.87-2.17,14-3.43c17-4.23,16.49-8.06,16.49-20.71V937H586Zm-28.56,42.36h-5.63V937H529.18v3.47H546v38.89H528.91v3.47h28.57v-3.47ZM493.81,937v3.47h9.73c9.6,0,10.09,5.85,10.09,12.58v29.79h6.08V957.17c0-11.64,0-20.17-15.5-20.17H493.81Zm-24.5,0H448.17v-9.4h-5.45v12.87H462L449.8,982.83h5.9Zm-31.49,42.36h-5.63V937H409.52v3.47h16.81v38.89H409.25v3.47h28.56v-3.47Zm-43.88-18.22h5.9V937h-5.9v24.14ZM351.08,937v3.47h5.5v38.89H347.7v3.47h14.73V940.47h5.09c7.25,0,9.33,3.58,9.33,13v29.32h5.95v-26c0-11.64-.27-19.81-15.95-19.81H351.08Z"
			      transform="translate(-319.63 -792.72)"/>
		</g>
	</svg>
)

export default () => (
	<div className="logo">
		{LogoShape}
		{LogoText}
	</div>
)