import FormField from '../Form/FormField'

const { Component } = React
const { observer } = mobxReact
const { observable } = mobx

@observer
export default class Tabs extends Component {
	@observable selectedTab

	constructor (props) {
		super(props)
		this.selectedTab = Object.keys(this.props.items)[0]
	}

	render () {
		const { items, schema, id, editMode } = this.props

		return (
			<div className="f_g d_f f_d_c">
				<div className="d_f tabs">
					{Object.keys(items).map((item, i) => {
						return (
							<div
								key={i}
								className={classNames('tab pad', {
									selected: this.selectedTab === item
								})}
								onClick={() => {
									this.selectedTab = item
								}}
							>
								{locale[item]}
							</div>
						)
					})}
				</div>

				{this.selectedTab && (
					<div className="f_g p_r o_y_a">
						<div className="p_a stretch">
							<div className="pad">
								{items[this.selectedTab].map(obj => {
									const {
										required,
										uiController,
										uiControllerSettings,
										key
									} = obj

									return (
										<div key={key}>
											<FormField
												required={required}
												uiController={uiController}
												uiControllerSettings={uiControllerSettings}
												name={key}
												form={id}
												label={locale[`${schema}_${key}`]}
												editMode={editMode}
											/>
										</div>
									)
								})}
							</div>
						</div>

					</div>
				)}
			</div>

		)
	}
}