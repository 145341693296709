import groupBy from 'lodash/groupBy'

import Form from '../templates/Form/Form'

const { Component } = React
const { observer } = mobxReact

import Tabs from '../templates/Tabs/Tabs'

@observer
export default class CreateDialog extends Component {
	constructor (props) {
		super(props)

		const { schema, editMode } = this.props

		if (editMode) {
			const editId = store.root.flags['editDialog']
			const editItem = store.root.database[schema][editId]
			Object.keys(editItem).forEach(key => {
				store.set(`forms.edit_${schema}.values.${key}.value`, editItem[key])
			})
		}
	}

	onClose = () => {
		if (store.root.flags['createDialog'])
			delete store.root.flags['createDialog']

		if (store.root.flags['editDialog'])
			delete store.root.flags['editDialog']
	}

	onSubmit = async (fields) => {
		const { schema, editMode } = this.props

		if (editMode) {
			await store.edit({ schema, fields })
		} else {
			store.create({ schema, fields })
		}

		this.onClose()
	}

	render () {
		const { schema, editMode } = this.props

		const id = `${editMode ? 'edit' : 'new'}_${schema}`
		const model = store.root.schemas[schema] || {}
		const columns = Object.keys(model)
		const groups = groupBy(columns.map(key => ({ ...model[key], key })), 'uiGroup')

		return (
			<Form
				id={id}
				onSubmit={this.onSubmit}
				custom
			>
				<div className="modal-c">
					<div className="mask mask_d p_a stretch"
					     onClick={this.onClose}/>

					<div className="p_a box modal-box min frame">
						<div className="modal-header pad ta_c">
							{editMode
								? <h3>{locale.edit} {locale[`${schema}`]}</h3>
								: <h3>{locale.new} {locale[`${schema}`]}</h3>
							}
						</div>

						<section>
							<Tabs
								items={groups}
								schema={schema}
								id={id}
								editMode={editMode}
							/>
						</section>

						<footer>
							<div className="d_f">
								<button type="cancel" className="f_1 gray ta_c"
								        onClick={this.onClose}
								>
									{locale['cancel']}
								</button>
								<button
									type="submit"
									className="f_1 main stretch_w ta_c"
								>
									{locale['save']}
								</button>
							</div>
						</footer>
					</div>
				</div>
			</Form>
		)
	}
}
