module.exports = {
	he: {
		admin_Asset: "ניהול תמונות",
		admin_Project: "ניהול פרויקטים",
		admin_BlogPost: "ניהול בלוג",

		Asset_title: "כותרת",
		Asset_subtitle: "כותרת משנה",
		Asset_url: "קישור",
		// Asset_tags: "תגיות",
		Asset_file: "תמונה",
		Asset_new: "העלאת תמונה",

		Project_title: "כותרת",
		Project_subtitle: "כותרת משנה",
		// Project_content: "תוכן",
		Project_images: "תמונות",
		Project_new: "פרויקט חדש",

		BlogPost_title: "כותרת",
		BlogPost_subtitle: "כותרת משנה",
		BlogPost_author: "מחבר",
		// BlogPost_content: "תוכן",
		BlogPost_image: "תמונה",
		BlogPost_new: "פוסט חדש",

		Project: 'פרויקט',
		Asset: 'תמונה',
		BlogPost: 'פוסט',

		new: "יצירת",
		edit: "עריכת",
		send: "שלח",
		save: "שמור",
		cancel: "ביטול",
		remove_warning_desc: "למחוק את",
		remove_warning_title: "אישור מחיקה",

		details: 'פרטים',
		images: 'תמונות',
		image: 'תמונה',
		content: 'תוכן',
		show: 'הצג',
		tags: 'תגיות',
		file: 'קובץ',
	}
}