const { Component } = React
const { observer } = mobxReact

import CreateDialog from './create-dialog'

@observer
export default class EditDialog extends Component {
	render () {
		const { schema } = this.props

		return (
			<CreateDialog
				schema={schema}
				editMode
			/>
		)
	}
}